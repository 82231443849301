@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* table,
th,
td {
  border: 1px solid gray;
} */

/* tbody tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
} */

.Sidebar-Link:hover span {
  /* background-color: rgba(44, 169, 227, 0.25) !important; */
  /* border-left: 8px solid #2ca9e3 !important; */
  color: #c0c0c0;
}
.Sidebar-Link:hover img {
  /* border-bottom: 1px solid #fff; */
}

button {
  text-transform: none !important;
}

a {
  text-decoration: none;
  color: inherit;
}
